* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.paginationbtns {
  list-style: none;
  display: flex;
  justify-content: end;
  align-items: center;
}

.paginationbtns a {
  padding: 5px;
  margin: 8px;
  border-radius: 5px;
  color: #5e6278;
  cursor: pointer;
}

.paginationbtns a:hover {
  /* color: white; */
}

.main {
  min-height: 80vh;
}

.paginationActive {
  background-color: #009ef7;
  border-color: #009ef7;
  color: #fff;
  z-index: 3;
  padding: 5px 5px 5px 5px;
  border-radius: 6px;
}

.feedback-table-container .table thead th {
  min-width: 30px;
  padding: 8px 16px;
}

.feedback-table-container .table tbody td {
  padding: 20px 16px;
}

.feedback-table-container .table tbody td:nth-child(4) { 
  min-width: 250px;
  /* max-width: 450px; */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedback-table-container .viewed-icon {
  font-size: 1.5em;
  cursor: pointer;
}

.feedback-table-container .toggle-container {
  display: flex;
  justify-content: flex-end; 
  margin-bottom: 1rem; 
}

.sidebar-title {
  padding: 15px 15px;
  /* background-color: #e8f6ff; */
  text-align: center;
  /* border-bottom: 3px solid #a2dbfd; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
  border-radius: 10px;
}

.sidebar-title h3 {
  margin: 0;
  font-size: 1.2em;
  color: #000000;
}

