.dashboard-stats-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dashboard-stats-container .paginationbtns {
  list-style: none;
  display: flex;
  justify-content: end;
  align-items: center;
}

.dashboard-stats-container .paginationbtns a {
  padding: 5px;
  margin: 8px;
  border-radius: 5px;
  color: #5e6278;
  cursor: pointer;
}

.dashboard-stats-container .main {
  min-height: 80vh;
}

.dashboard-stats-container .paginationActive {
  background-color: #009ef7;
  border-color: #009ef7;
  color: #fff;
  z-index: 3;
  padding: 5px 5px 5px 5px;
  border-radius: 6px;
}

.dashboard-stats-container .feedback-table-container .table thead th {
  min-width: 30px;
  padding: 8px 16px;
}

.dashboard-stats-container .feedback-table-container .table tbody td {
  padding: 20px 16px;
}

.dashboard-stats-container .feedback-table-container .table tbody td:nth-child(4) {
  min-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-stats-container .feedback-table-container .viewed-icon {
  font-size: 1.5em;
  cursor: pointer;
}

.dashboard-stats-container .feedback-table-container .toggle-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.dashboard-stats-container .sidebar-title {
  padding: 15px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.dashboard-stats-container .sidebar-title h3 {
  margin: 0;
  font-size: 1.2em;
  color: #000000;
}


.dashboard-stats-container .row {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-stats-container .col {
  padding: 15px;
  display: flex;
  flex: 1; 
}


.dashboard-stats-container .col .card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.dashboard-stats-container .card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.dashboard-stats-container .card-header {
  display: flex;
  align-items: center; 
  justify-content: center; 
  padding: 1rem; 
  background: none;
  border-bottom: 1px solid #eee;
}
.dashboard-stats-container .card .card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: none;
  border-bottom: 1px solid #eee;
}

.dashboard-stats-container .card-header h2 {
  font-size: 1.5rem;
  color: #333;
}

.dashboard-stats-container .card-body {
  display: flex; 
  align-items: stretch; 
  justify-content: space-between; 
  padding: 1rem;
  flex: 1; 
}

@media (max-width: 768px) {
  .card-body {
    flex-direction: column; 
  }
}

.dashboard-stats-container .card-body .table-responsive,
.dashboard-stats-container .card-body .chart-container {
  flex: 1; 
  padding: 0 0.5rem; 
}


.dashboard-stats-container .card-footer {
  padding: 0.5rem 1rem;
  background: none;
  border-top: none;
}

.dashboard-stats-container .table-small {
  width: auto;
  margin: 0 auto;
}

.card .table-small th,
.card .table-small td {
  padding: 0.5rem;
}


.card .table {
  width: 100%;
  margin-bottom: 0;
}

.card .table th,
.card .table td {
  padding: 0.5rem;
}

.card .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.dashboard-stats-container .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.dashboard-stats-container .col-lg-6 {
  display: flex;
  flex: 1 0 50%; 
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px; 
}

.dashboard-stats-container .stats-container {
  padding-left: 20px; 
}

.stats-list {
  list-style-type: disc; 
  padding-left: 20px; 
}

.stats-list li {
  margin-bottom: 10px; 
  font-weight: normal;
}

.stats-list li strong {
  font-weight: bold;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: red;
  text-align: center;
}

.card-footer {
  width: 100%;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
}

.page-button {
  padding: 0.5rem 1rem; 
  border: 1px solid #ddd; 
  background-color: #fff; 
  cursor: pointer;
}

.page-button:disabled {
  color: #ccc; 
  cursor: not-allowed;
}

.dashboard-stats-container .col .card {
  margin-bottom: 50px !important; 
}

.dashboard-stats-container .row {
  margin-right: 0;
  margin-left: 0;
}

.dashboard-stats-container .col {
  padding-right: 15px;
  padding-left: 15px;
}

.dashboard-stats-container .row:last-child .card {
  margin-bottom: 30px; 
}